var render = function () {
  var _vm$previewData;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.previewData !== null ? _c('div', {
    staticClass: "invoice-preview"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "border-0 bg-white font-weight-bolder",
    staticStyle: {
      "color": "#3DA5DE",
      "padding": "9.5px 16px"
    },
    on: {
      "click": _vm.generatePDF
    }
  }, [_c('img', {
    staticClass: "mr-25",
    attrs: {
      "src": require("@icons/downloadFile.svg"),
      "alt": "Download",
      "width": "24px"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.download')) + " ")])]), _c('button', {
    staticClass: "border-0 bg-white font-weight-bolder",
    staticStyle: {
      "color": "#3DA5DE",
      "padding": "9.5px 16px"
    },
    on: {
      "click": function click($event) {
        return _vm.onPrintInvoice();
      }
    }
  }, [_c('img', {
    staticClass: "mr-25",
    attrs: {
      "src": require("@icons/print.svg"),
      "alt": "Print",
      "width": "24px"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.print')) + " ")])])]), _c('div', {
    ref: "pdfContainer",
    staticClass: "canvas_div_pdf",
    staticStyle: {
      "width": "920px",
      "text-align": "center"
    }
  }, [_c('table', {
    staticStyle: {
      "padding-left": "5px",
      "width": "900px",
      "border-top": "double",
      "border-bottom": "double",
      "border-left": "double",
      "border-right": "double"
    },
    attrs: {
      "align": "center"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticClass: "headerCSS",
    staticStyle: {
      "width": "245px",
      "text-align": "center"
    },
    attrs: {
      "rowspan": "4"
    }
  }), _c('td', {
    staticStyle: {
      "width": "450px",
      "text-align": "center",
      "font-weight": "bold",
      "font-size": "20px",
      "margin-top": "5px"
    }
  }, [_vm._v(" HÓA ĐƠN GIÁ TRỊ GIA TĂNG ")]), _c('td', [_vm._v(" Mẫu số "), _c('i', [_vm._v("(Form)")]), _vm._v(": " + _vm._s(_vm.previewData.form) + " ")])]), _c('tr', [_vm._m(0), _c('td', [_vm._v(" Ký hiệu "), _c('i', [_vm._v("(Serial)")]), _vm._v(": " + _vm._s(_vm.previewData.serial) + " ")])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "450px",
      "text-align": "center",
      "font-size": "14px"
    }
  }, [_vm._v(" Ngày "), _c('i', [_vm._v("(Day)")]), _c('b', [_vm._v(" " + _vm._s(_vm.previewData.publicDate.day) + " ")]), _vm._v(" tháng "), _c('i', [_vm._v("(month)")]), _c('b', [_vm._v(" " + _vm._s(_vm.previewData.publicDate.month) + " ")]), _vm._v(" năm "), _c('i', [_vm._v("(year)")]), _c('b', [_vm._v(" " + _vm._s(_vm.previewData.publicDate.year) + " ")])]), _c('td', [_vm._v(" Số "), _c('i', [_vm._v("(No)")]), _vm._v(": "), _c('b', [_vm._v(" " + _vm._s(_vm.previewData.invoiceNumber) + " ")])])]), _c('tr', [_c('td', {
    staticClass: "headerCSS",
    staticStyle: {
      "width": "450px",
      "text-align": "center",
      "font-size": "14px"
    }
  }, [_vm._v(" Mã CQT: " + _vm._s(_vm.previewData.taCode) + " ")]), _c('td', {
    staticClass: "headerCSS"
  })]), _c('tr', [_c('td', {
    staticClass: "headerCSS",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Đơn vị bán hàng"), _c('i', [_vm._v("(Seller):")]), _c('b', [_vm._v(" " + _vm._s(_vm.previewData.issuerName) + " ")])])]), _c('tr', [_c('td', {
    staticClass: "headerCSS",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Mã số thuế"), _c('i', [_vm._v("(Tax Code):")]), _vm._v(" " + _vm._s(_vm.previewData.issuerTaxCode) + " ")])]), _c('tr', [_c('td', {
    staticClass: "headerCSS",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Địa chỉ"), _c('i', [_vm._v("(Seller):")]), _vm._v(" " + _vm._s(_vm.previewData.issuerAddress) + " ")])]), _c('tr', [_c('td', {
    staticClass: "headerCSS",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Điện thoại"), _c('i', [_vm._v("(Tel):")]), _vm._v(" " + _vm._s(_vm.previewData.issuerPhoneNumber) + " ")])]), _c('tr', [_c('td', {
    staticClass: "headerCSS",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Số tài khoản"), _c('i', [_vm._v("(Bank No):")]), _vm._v(" " + _vm._s(_vm.previewData.issuerBankAccountNo) + " tại " + _vm._s(_vm.previewData.issuerBankAccountName) + " ")])]), _c('tr', [_c('td', {
    staticClass: "headerCSS",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Họ tên khách hàng"), _c('i', [_vm._v("(Guest Name):")]), _vm._v(" " + _vm._s(_vm.previewData.guestName) + " ")])]), _c('tr', [_c('td', {
    staticClass: "headerCSS",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Tên đơn vị"), _c('i', [_vm._v("(Company):")]), _vm._v(" " + _vm._s(_vm.previewData.companyName) + " ")])]), _c('tr', [_c('td', {
    staticClass: "headerCSS",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Mã số thuế"), _c('i', [_vm._v("(Tax Code):")]), _vm._v(" " + _vm._s(_vm.previewData.taxCode) + " ")])]), _c('tr', [_c('td', {
    staticClass: "headerCSS",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Địa chỉ"), _c('i', [_vm._v("(Address):")]), _vm._v(" " + _vm._s(_vm.previewData.address) + " ")])]), _c('tr', [_c('td', {
    staticClass: "headerCSS",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Hình thức thanh toán"), _c('i', [_vm._v("(Method of payment):")]), _vm._v(" " + _vm._s(_vm.parsePaymentMethod(_vm.previewData.paymentMethod)) + " ")])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_c('table', {
    staticStyle: {
      "width": "900px",
      "margin-bottom": "0px",
      "border": "0px"
    }
  }, [_c('tbody', [_vm._m(1), _vm._l(_vm.previewData.invoiceItems, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "headerCSS3",
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', {
      staticClass: "headerCSS3",
      staticStyle: {
        "text-align": "left"
      }
    }, [_vm._v(" " + _vm._s(item.description) + " ")]), _c('td', {
      staticClass: "headerCSS3",
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(item.unit) + " ")]), _c('td', {
      staticClass: "headerCSS3",
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(item.quantity) + " ")]), _c('td', {
      staticClass: "headerCSS3",
      staticStyle: {
        "text-align": "right"
      }
    }, [_vm._v(" " + _vm._s(item.unitPrice) + " ")]), _c('td', {
      staticClass: "headerCSS3",
      staticStyle: {
        "text-align": "right"
      }
    }, [_vm._v(" " + _vm._s(item.beforeTaxAmount) + " ")]), _c('td', {
      staticClass: "headerCSS3",
      staticStyle: {
        "text-align": "right"
      }
    }, [_vm._v(" " + _vm._s(item.vatRate) + "% ")]), _c('td', {
      staticClass: "headerCSS3",
      staticStyle: {
        "text-align": "right"
      }
    }, [_vm._v(" " + _vm._s(item.vatAmount) + " ")]), _c('td', {
      staticClass: "headerCSS3",
      staticStyle: {
        "text-align": "right"
      }
    }, [_vm._v(" " + _vm._s(item.amount) + " ")])]);
  }), _c('tr', [_vm._m(2), _c('td', {
    staticClass: "headerCSS3",
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm._v(" " + _vm._s(_vm.previewData.totalAmountBeforeTax) + " ")]), _c('td', {
    staticClass: "headerCSS3",
    staticStyle: {
      "text-align": "right"
    }
  }), _c('td', {
    staticClass: "headerCSS3",
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm._v(" " + _vm._s(_vm.previewData.totalVat) + " ")]), _c('td', {
    staticClass: "headerCSS3",
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.previewData.totalAmount) + " ")])])])], 2)])])]), _c('tr', [_vm._m(3), _c('td', {
    staticClass: "headerCSS",
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.previewData.totalCollectionFee) + " ")])])]), _c('tr', [_vm._m(4), _c('td', {
    staticClass: "headerCSS",
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.previewData.grandTotal) + " ")])])]), _c('tr', [_c('td', {
    staticClass: "headerCSS",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Số tiền viết bằng chữ"), _c('i', [_vm._v("(Amount in words):")]), _vm._v(" " + _vm._s((_vm$previewData = _vm.previewData) === null || _vm$previewData === void 0 ? void 0 : _vm$previewData.grandTotal2words) + " đồng ")])]), _c('tr', [_c('td', {
    staticClass: "headerCSS",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Hóa đơn điện tử được tra cứu tại: "), _c('a', {
    attrs: {
      "href": "".concat(_vm.publicUrl, "?code=").concat(_vm.previewData.searchCode),
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.publicUrl) + " ")]), _vm._v(" Mã tra cứu HĐĐT này: "), _c('span', {
    staticClass: "cursor-pointer",
    staticStyle: {
      "color": "#3DA5DE"
    },
    attrs: {
      "title": _vm.$t('invoice.copyToClipboard')
    },
    on: {
      "click": function click($event) {
        return _vm.onCopySearchCodeToClipboard();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.previewData.searchCode) + " ")])])]), _vm._m(5), _vm._m(6), _c('tr', {
    style: _vm.previewData.status === _vm.INVOICE_STATUS_VALUES.released ? 'opacity: 1;' : 'opacity: 0;'
  }, [_c('td', {
    staticStyle: {
      "height": "60px"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_c('table', [_c('tbody', [_vm._m(7), _c('tr', [_c('td', {
    staticStyle: {
      "width": "260px",
      "font-size": "13px",
      "color": "red"
    }
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.previewData.issuerName) + " ")])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "260px",
      "font-size": "13px",
      "color": "red"
    }
  }, [_c('b', [_vm._v(" Ngày: " + _vm._s(_vm.previewData.publicDate.date) + " ")])])])])])])]), _vm._m(8)])])])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticStyle: {
      "width": "450px",
      "text-align": "center",
      "font-weight": "bold",
      "font-size": "18px"
    }
  }, [_c('i', [_vm._v("(VAT INVOICE)")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', {
    staticClass: "headerCSS9999",
    staticStyle: {
      "width": "30px",
      "text-align": "center"
    }
  }, [_c('b', [_vm._v("STT")]), _c('br'), _c('i', [_vm._v("(No.)")])]), _c('td', {
    staticClass: "headerCSS9999",
    staticStyle: {
      "width": "270px",
      "text-align": "center"
    }
  }, [_c('b', [_vm._v("Tên hàng hóa, dịch vụ")]), _c('br'), _c('i', [_vm._v("(Descriptions)")])]), _c('td', {
    staticClass: "headerCSS9999",
    staticStyle: {
      "width": "60px",
      "text-align": "center"
    }
  }, [_c('b', [_vm._v("Đơn vị tính")]), _c('br'), _c('i', [_vm._v("(Unit)")])]), _c('td', {
    staticClass: "headerCSS9999",
    staticStyle: {
      "width": "40px",
      "text-align": "center"
    }
  }, [_c('b', [_vm._v("Số lượng")]), _c('br'), _c('i', [_vm._v("(Quantity)")])]), _c('td', {
    staticClass: "headerCSS9999",
    staticStyle: {
      "width": "110px",
      "text-align": "center"
    }
  }, [_c('b', [_vm._v("Đơn giá")]), _c('br'), _c('i', [_vm._v("(Unit Price)")])]), _c('td', {
    staticClass: "headerCSS9999",
    staticStyle: {
      "width": "110px",
      "text-align": "center"
    }
  }, [_c('b', [_vm._v("Số tiền trước thuế")]), _c('br'), _c('i', [_vm._v("(Before tax amount)")])]), _c('td', {
    staticClass: "headerCSS9999",
    staticStyle: {
      "width": "70px",
      "text-align": "center"
    }
  }, [_c('b', [_vm._v("Thuế suất")]), _c('br'), _c('i', [_vm._v("(% VAT rate)")])]), _c('td', {
    staticClass: "headerCSS9999",
    staticStyle: {
      "width": "90px",
      "text-align": "center"
    }
  }, [_c('b', [_vm._v("Tiền thuế GTGT")]), _c('br'), _c('i', [_vm._v("(Vat Tax)")])]), _c('td', {
    staticClass: "headerCSS9999",
    staticStyle: {
      "width": "120px",
      "text-align": "center"
    }
  }, [_c('b', [_vm._v("Thành tiền")]), _c('br'), _c('i', [_vm._v("(Amount)")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "headerCSS3",
    attrs: {
      "colspan": "5"
    }
  }, [_c('b', [_vm._v("1.Tổng cộng ")]), _vm._v(" "), _c('i', [_vm._v("(Total)")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "headerCSS",
    attrs: {
      "colspan": "2"
    }
  }, [_c('b', [_vm._v("2. Các khoản thuế, phí thu hộ nhà chức trách")]), _vm._v(" "), _c('i', [_vm._v("(Authorized collection):")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "headerCSS",
    attrs: {
      "colspan": "2"
    }
  }, [_c('b', [_vm._v("Tổng cộng tiền thanh toán")]), _c('i', [_vm._v("(Grand Total)")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', {
    staticStyle: {
      "height": "20px"
    },
    attrs: {
      "colspan": "3"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm._v(" Người mua hàng "), _c('i', [_vm._v("(Buyer)")])]), _c('td', {
    staticStyle: {
      "padding-left": "310px"
    },
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" Người bán hàng "), _c('i', [_vm._v("(Seller)")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', {
    staticStyle: {
      "width": "570px"
    },
    attrs: {
      "rowspan": "3"
    }
  }), _c('td', {
    staticStyle: {
      "width": "70px"
    },
    attrs: {
      "rowspan": "3"
    }
  }), _c('td', {
    staticStyle: {
      "width": "260px",
      "font-size": "13px",
      "color": "red"
    }
  }, [_c('b', [_vm._v("Ký bởi:")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', {
    staticStyle: {
      "height": "50px",
      "text-align": "center",
      "vertical-align": "bottom",
      "font-size": "smaller"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_c('i', [_vm._v(" Đơn vị cung cấp giải pháp hóa đơn điện tử : Công ty AirDataVn - MST: 0123456789, website: "), _c('a', {
    attrs: {
      "href": "https://airdata.vn",
      "target": "_blank"
    }
  }, [_vm._v(" https://airdata.vn ")])])])]);
}]

export { render, staticRenderFns }