<template>
  <div
    v-if="previewData !== null"
    class="invoice-preview"
  >
    <div class="d-flex justify-content-end">
      <button
        class="border-0 bg-white font-weight-bolder"
        style="color: #3DA5DE; padding: 9.5px 16px;"
        @click="generatePDF"
      >
        <img
          src="@icons/downloadFile.svg"
          alt="Download"
          width="24px"
          class="mr-25"
        >
        <span class="align-middle">
          {{ $t('invoice.download') }}
        </span>
      </button>
      <button
        class="border-0 bg-white font-weight-bolder"
        style="color: #3DA5DE; padding: 9.5px 16px;"
        @click="onPrintInvoice()"
      >
        <img
          src="@icons/print.svg"
          alt="Print"
          width="24px"
          class="mr-25"
        >
        <span class="align-middle">
          {{ $t('invoice.print') }}
        </span>
      </button>
    </div>

    <div
      ref="pdfContainer"
      class="canvas_div_pdf"
      style="width:920px; text-align:center;"
    >
      <table
        align="center"
        style="padding-left:5px; width:900px; border-top:double; border-bottom:double; border-left:double; border-right:double; "
      >
        <tbody>
          <tr>
            <td
              class="headerCSS"
              style="width:245px; text-align:center;"
              rowspan="4"
            >
              <!-- <img
                :src="require('@/assets/images/preview-invoice/LOGO.png')"
                style="padding:10px 0px; width:151px; height:90px;"
              > -->
            </td>
            <td style="width:450px; text-align:center; font-weight:bold; font-size: 20px; margin-top:5px">
              HÓA ĐƠN GIÁ TRỊ GIA TĂNG
            </td>
            <td>
              Mẫu số <i>(Form)</i>: {{ previewData.form }}
            </td>
          </tr>
          <tr>
            <td style="width:450px; text-align:center; font-weight:bold; font-size: 18px">
              <i>(VAT INVOICE)</i>
            </td>
            <td>
              Ký hiệu <i>(Serial)</i>:
              {{ previewData.serial }}
            </td>
          </tr>
          <tr>
            <td style="width:450px; text-align:center; font-size: 14px;">
              Ngày <i>(Day)</i>
              <b>
                {{ previewData.publicDate.day }}
              </b>
              tháng <i>(month)</i>
              <b>
                {{ previewData.publicDate.month }}
              </b>
              năm <i>(year)</i>
              <b>
                {{ previewData.publicDate.year }}
              </b>
            </td>
            <td>
              Số <i>(No)</i>:
              <b>
                {{ previewData.invoiceNumber }}
              </b>
            </td>
          </tr>
          <tr>
            <td
              class="headerCSS"
              style="width:450px; text-align:center; font-size: 14px"
            >
              Mã CQT:
              {{ previewData.taCode }}
            </td>
            <td class="headerCSS" />
          </tr>

          <tr>
            <td
              class="headerCSS"
              colspan="3"
            >
              Đơn vị bán hàng<i>(Seller):</i>
              <b>
                {{ previewData.issuerName }}
              </b>
            </td>
          </tr>
          <tr>
            <td
              class="headerCSS"
              colspan="3"
            >
              Mã số thuế<i>(Tax Code):</i>
              {{ previewData.issuerTaxCode }}
            </td>
          </tr>
          <tr>
            <td
              class="headerCSS"
              colspan="3"
            >
              Địa chỉ<i>(Seller):</i>
              {{ previewData.issuerAddress }}
            </td>
          </tr>
          <tr>
            <td
              class="headerCSS"
              colspan="3"
            >
              Điện thoại<i>(Tel):</i>
              {{ previewData.issuerPhoneNumber }}
            </td>
          </tr>
          <tr>
            <td
              class="headerCSS"
              colspan="3"
            >
              Số tài khoản<i>(Bank No):</i>
              {{ previewData.issuerBankAccountNo }}
              tại
              {{ previewData.issuerBankAccountName }}
            </td>
          </tr>
          <tr>
            <td
              class="headerCSS"
              colspan="3"
            >
              Họ tên khách hàng<i>(Guest Name):</i>
              {{ previewData.guestName }}
            </td>
          </tr>
          <tr>
            <td
              class="headerCSS"
              colspan="3"
            >
              Tên đơn vị<i>(Company):</i>
              {{ previewData.companyName }}
            </td>
          </tr>
          <tr>
            <td
              class="headerCSS"
              colspan="3"
            >
              Mã số thuế<i>(Tax Code):</i>
              {{ previewData.taxCode }}
            </td>
          </tr>
          <tr>
            <td
              class="headerCSS"
              colspan="3"
            >
              Địa chỉ<i>(Address):</i>
              {{ previewData.address }}
            </td>
          </tr>
          <tr>
            <td
              class="headerCSS"
              colspan="3"
            >
              Hình thức thanh toán<i>(Method of payment):</i>
              {{ parsePaymentMethod(previewData.paymentMethod) }}
            </td>
          </tr>

          <tr>
            <td colspan="3">
              <table style="width:900px; margin-bottom:0px; border:0px; ">
                <tbody>
                  <tr>
                    <td
                      class="headerCSS9999"
                      style="width:30px; text-align:center"
                    >
                      <b>STT</b><br><i>(No.)</i>
                    </td>
                    <td
                      class="headerCSS9999"
                      style="width:270px; text-align:center"
                    >
                      <b>Tên hàng hóa, dịch vụ</b><br><i>(Descriptions)</i>
                    </td>
                    <td
                      class="headerCSS9999"
                      style="width:60px; text-align:center"
                    >
                      <b>Đơn vị tính</b><br><i>(Unit)</i>
                    </td>
                    <td
                      class="headerCSS9999"
                      style="width:40px; text-align:center"
                    >
                      <b>Số lượng</b><br><i>(Quantity)</i>
                    </td>
                    <td
                      class="headerCSS9999"
                      style="width:110px; text-align:center"
                    >
                      <b>Đơn giá</b><br><i>(Unit Price)</i>
                    </td>
                    <td
                      class="headerCSS9999"
                      style="width:110px; text-align:center"
                    >
                      <b>Số tiền trước thuế</b><br><i>(Before tax amount)</i>
                    </td>
                    <td
                      class="headerCSS9999"
                      style="width:70px; text-align:center"
                    >
                      <b>Thuế suất</b><br><i>(% VAT rate)</i>
                    </td>
                    <td
                      class="headerCSS9999"
                      style="width:90px; text-align:center"
                    >
                      <b>Tiền thuế GTGT</b><br><i>(Vat Tax)</i>
                    </td>
                    <td
                      class="headerCSS9999"
                      style="width:120px; text-align:center"
                    >
                      <b>Thành tiền</b><br><i>(Amount)</i>
                    </td>
                  </tr>

                  <!-- invoice items -->
                  <tr
                    v-for="(item,index) in previewData.invoiceItems"
                    :key="index"
                  >
                    <td
                      class="headerCSS3"
                      style="text-align:center;"
                    >
                      {{ index + 1 }}
                    </td>
                    <td
                      class="headerCSS3"
                      style="text-align:left ;"
                    >
                      {{ item.description }}
                    </td>
                    <td
                      class="headerCSS3"
                      style="text-align:center;"
                    >
                      {{ item.unit }}
                    </td>
                    <td
                      class="headerCSS3"
                      style="text-align:center;"
                    >
                      {{ item.quantity }}
                    </td>
                    <td
                      class="headerCSS3"
                      style="text-align:right;"
                    >
                      {{ item.unitPrice }}
                    </td>
                    <td
                      class="headerCSS3"
                      style="text-align:right;"
                    >
                      {{ item.beforeTaxAmount }}
                    </td>
                    <td
                      class="headerCSS3"
                      style="text-align:right;"
                    >
                      {{ item.vatRate }}%
                    </td>
                    <td
                      class="headerCSS3"
                      style="text-align:right;"
                    >
                      {{ item.vatAmount }}
                    </td>
                    <td
                      class="headerCSS3"
                      style="text-align:right;"
                    >
                      {{ item.amount }}
                    </td>
                  </tr>

                  <tr>
                    <td
                      class="headerCSS3"
                      colspan="5"
                    >
                      <b>1.Tổng cộng </b> <i>(Total)</i>
                    </td>
                    <td
                      class="headerCSS3"
                      style="text-align:right;"
                    >
                      {{ previewData.totalAmountBeforeTax }}
                    </td>
                    <td
                      class="headerCSS3"
                      style="text-align:right;"
                    />
                    <td
                      style="text-align:right;"
                      class="headerCSS3"
                    >
                      {{ previewData.totalVat }}
                    </td>
                    <td
                      class="headerCSS3"
                      style="text-align:right;"
                    >
                      <b>
                        {{ previewData.totalAmount }}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td
              class="headerCSS"
              colspan="2"
            >
              <b>2. Các khoản thuế, phí thu hộ nhà chức trách</b> <i>(Authorized collection):</i>
            </td>
            <td
              class="headerCSS"
              style="text-align: right;"
            >
              <b>
                {{ previewData.totalCollectionFee }}
              </b>
            </td>
          </tr>
          <tr>
            <td
              class="headerCSS"
              colspan="2"
            >
              <b>Tổng cộng tiền thanh toán</b><i>(Grand Total)</i>
            </td>
            <td
              class="headerCSS"
              style="text-align: right;"
            >
              <b>
                {{ previewData.grandTotal }}
              </b>
            </td>
          </tr>
          <tr>
            <td
              class="headerCSS"
              colspan="3"
            >
              Số tiền viết bằng chữ<i>(Amount in words):</i>
              {{ previewData?.grandTotal2words }}
              đồng
            </td>
          </tr>
          <tr>
            <td
              class="headerCSS"
              colspan="3"
            >
              Hóa đơn điện tử được tra cứu tại:
              <a
                :href="`${publicUrl}?code=${previewData.searchCode}`"
                target="_blank"
              >
                {{ publicUrl }}
              </a>
              Mã tra cứu HĐĐT này:
              <span
                class="cursor-pointer"
                style="color:#3DA5DE;"
                :title="$t('invoice.copyToClipboard')"
                @click="onCopySearchCodeToClipboard()"
              >
                {{ previewData.searchCode }}
              </span>
            </td>
          </tr>
          <tr>
            <td
              style="height:20px"
              colspan="3"
            />
          </tr>
          <tr>
            <td style="text-align:right">
              Người mua hàng <i>(Buyer)</i>
            </td>
            <td
              colspan="2"
              style="padding-left: 310px;"
            >
              Người bán hàng <i>(Seller)</i>
            </td>
          </tr>

          <tr :style="previewData.status === INVOICE_STATUS_VALUES.released ? 'opacity: 1;' : 'opacity: 0;'">
            <td
              style="height:60px;"
              colspan="3"
            >
              <table>
                <tbody>
                  <tr>
                    <td
                      style="width:570px;"
                      rowspan="3"
                    />
                    <td
                      style="width:70px;"
                      rowspan="3"
                    >
                      <!-- <img
                        :src="require('@/assets/images/preview-invoice/Ky.png')"
                        style="width:70px; height:60px;"
                      > -->
                    </td>
                    <td style="width:260px; font-size:13px; color:red;">
                      <b>Ký bởi:</b>
                    </td>
                  </tr>
                  <tr>
                    <td style="width:260px; font-size: 13px; color:red;">
                      <b>
                        {{ previewData.issuerName }}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td style="width:260px; font-size: 13px; color:red;">
                      <b>
                        Ngày:
                        {{ previewData.publicDate.date }}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td
              style="height:50px; text-align:center; vertical-align:bottom; font-size:smaller;"
              colspan="3"
            >
              <!-- TODO: correct content: -->
              <i>
                Đơn vị cung cấp giải pháp hóa đơn điện tử :
                Công ty AirDataVn
                - MST:
                0123456789,
                website:
                <a
                  href="https://airdata.vn"
                  target="_blank"
                >
                  https://airdata.vn
                </a>
              </i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

import {
  INVOICE_STATUS_VALUES,
  parsePaymentMethod,
} from '@/constants/invoice'

export default {
  name: 'InvoicePreview',
  props: {
    previewData: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const publicUrl = process.env.VUE_APP_PUBLIC_INVOICE_SEARCH

    function generatePDF() {
      const canvasDivPdf = this.$refs.pdfContainer

      const htmlWidth = canvasDivPdf.offsetWidth
      const htmlHeight = canvasDivPdf.offsetHeight
      const topLeftMargin = 20
      const pdfWidth = htmlWidth + (topLeftMargin * 2)
      const pdfHeight = (pdfWidth * 1.5) + (topLeftMargin * 2)
      const canvasImgWidth = htmlWidth
      const canvasImgHeight = htmlHeight

      const totalPDFPages = Math.ceil(htmlHeight / pdfHeight) - 1

      html2canvas(canvasDivPdf, {
        allowTaint: true,
      }).then(canvas => {
        canvas.getContext('2d')

        const imgData = canvas.toDataURL('image/jpeg', 1.0)
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight])
        pdf.addImage(imgData, 'JPG', topLeftMargin, topLeftMargin, canvasImgWidth, canvasImgHeight)

        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= totalPDFPages; i++) {
          pdf.addPage(pdfWidth, pdfHeight)
          pdf.addImage(imgData, 'JPG', topLeftMargin, -(pdfHeight * i) + (topLeftMargin * 1.5), canvasImgWidth, canvasImgHeight)
        }

        const FileName = `HoaDon_${this.previewData.form || 1}${this.previewData.serial || ''}_${this.previewData.invoiceNumber || ''}.pdf`
        pdf.save(FileName)
      })
    }

    function onCopySearchCodeToClipboard() {
      navigator.clipboard.writeText(props.previewData.searchCode)
    }

    function onPrintInvoice() {
      const pdfContainer = this.$refs.pdfContainer

      const printWindow = window.open('', 'PRINT', 'height=400,width=600')

      printWindow.document.write(`<html><head><title>${document.title}</title>`)
      printWindow.document.write('</head><body >')
      printWindow.document.write(`<h1>${document.title}</h1>`)
      printWindow.document.write(pdfContainer.innerHTML)
      printWindow.document.write('</body></html>')

      printWindow.document.close() // necessary for IE >= 10
      printWindow.focus() // necessary for IE >= 10*/

      printWindow.print()
      printWindow.close()

      return true
    }

    return {
      INVOICE_STATUS_VALUES,
      publicUrl,
      parsePaymentMethod,
      generatePDF,
      onCopySearchCodeToClipboard,
      onPrintInvoice,
    }
  },
}
</script>

<style lang="scss">
.invoice-preview {
  font-family: Times;
  color: black;
  line-height: normal;

  table {
    border-collapse: separate;
    text-indent: initial;
    white-space: normal;
    line-height: normal;
    font-weight: normal;
    font-size: medium;
    font-style: normal;
    color: -internal-quirk-inherit;
    text-align: start;
    border-spacing: 2px;
    font-variant: normal;
  }

  button {
    outline: none;
  }
}
.headerCSS9999 {
  background: url('../../../../../public/preview-invoice/alldate-bg3.png') repeat-x scroll center bottom transparent;
  border-bottom:1px solid #cccccc;
  border-right:1px solid #cccccc;
  border-top:1px solid #cccccc;
  color:#000066;
  height: 22px;
}
.headerCSS3 {
  border-bottom:1px solid #cccccc;
  height:30px;color:#000;
  text-align:left;
  border-right:1px solid #cccccc;
}
.headerCSS {
  border-bottom:1px solid #cccccc;
  height:30px;color:#000;
}
.headerCSS2 {
  border-bottom:1px solid #cccccc;
  border-right:1px solid #cccccc;
  border-top:1px solid #cccccc;
  color:#000;
  height: 30px;
}
</style>
