<template>
  <b-modal
    :id="idPopupPreview"
    size="948px"
    centered
    header-bg-variant="white"
    body-class="px-1 pt-0 pb-1"
    hide-footer
    title-class="h2 header-class text-dark"
    :title="$t('invoice.detail')"
  >
    <InvoicePreview
      :preview-data="$attrs['preview-data']"
    />
  </b-modal>
</template>

<script>
import InvoicePreview from '../invoice-preview/InvoicePreview.vue'

export default {
  name: 'PopupPreviewInvoice',

  components: {
    InvoicePreview,
  },

  inheritAttrs: false,

  props: {
    idPopupPreview: {
      type: String,
      default: 'id-invoice-popup-preview',
    },
    invoiceData: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss">
.modal-dialog.modal-948px {
  width: 948px !important;
  max-width: 948px !important;
}
</style>
