var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.idPopupPreview,
      "size": "948px",
      "centered": "",
      "header-bg-variant": "white",
      "body-class": "px-1 pt-0 pb-1",
      "hide-footer": "",
      "title-class": "h2 header-class text-dark",
      "title": _vm.$t('invoice.detail')
    }
  }, [_c('InvoicePreview', {
    attrs: {
      "preview-data": _vm.$attrs['preview-data']
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }